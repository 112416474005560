import {
    CLEAR_GITHUB_TOKEN,
    CLEAR_JIRA_TOKEN,
    FETCHING_GITHUB_CODE,
    FETCHING_GITHUB_TOKEN,
    FETCHING_JIRA_CODE,
    FETCHING_JIRA_TOKEN,
    SET_GITHUB_TOKEN,
    SET_JIRA_TOKEN
} from "../actionTypes";

const initialState = {
    githubToken: null,
    fetchingGithubCode: false,
    fetchingGithubToken: false,

    jiraToken: null,
    fetchingJiraCode: false,
    fetchingJiraToken: false
};

const auth = (state = initialState, action) => {
    switch (action.type) {
    case FETCHING_GITHUB_CODE:
        return { ...state, fetchingGithubCode: true, fetchingGithubToken: false, githubToken: null};
    case FETCHING_GITHUB_TOKEN:
        return { ...state, fetchingGithubToken: true, fetchingGithubCode: false, githubToken: null };
    case SET_GITHUB_TOKEN:{
        const { githubToken } = action.payload;
        return { ...state, githubToken, fetchingGithubCode: false, fetchingGithubToken: false };
    }
    case CLEAR_GITHUB_TOKEN:
        return auth(state, { type: SET_GITHUB_TOKEN, payload: { githubToken: null } });

    case FETCHING_JIRA_CODE:
        return { ...state, fetchingJiraCode: true, fetchingJiraToken: false, jiraToken: null };
    case FETCHING_JIRA_TOKEN:
        return { ...state, fetchingJiraToken: true, fetchingJiraCode: false, jiraToken: null };
    case SET_JIRA_TOKEN: {
        const { jiraToken } = action.payload;
        return { ...state, jiraToken, fetchingJiraCode: false, fetchingJiraToken: false };
    }
    case CLEAR_JIRA_TOKEN:
        return auth(state, { type: SET_JIRA_TOKEN, payload: { jiraToken: null } });

    default:
        return state;
    }
};

export default auth;
