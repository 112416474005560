import React from 'react';
import { connect } from "react-redux";
import { fetchGithubRepositories } from "../redux/actions";
import { Accordion, Badge, Button, Card } from "react-bootstrap";
import { GithubButton, JiraButton } from "./authButtons";
import PullRequestsSelector from "./PullRequestsSelector";
import PropTypes from "prop-types";
import IssueDescription from "./issueDescription";
import { IntlProvider } from "react-intl";
import { ReactRenderer } from "@atlaskit/renderer";
import Publish from "./Publish";

const COLLAPSE_KEY_AUTH = 'COLLAPSE_KEY_AUTH';
const COLLAPSE_KEY_GITHUB_PULLS = 'COLLAPSE_KEY_GITHUB_PULLS';
const COLLAPSE_KEY_PREVIEW = 'COLLAPSE_KEY_PREVIEW';
const COLLAPSE_KEY_PUBLISH = 'COLLAPSE_KEY_PUBLISH';

class ReleaseTicketComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            overrideActiveKey: false,
            activeKey: COLLAPSE_KEY_AUTH,
            issueDescriptionForPreview: null,
            issueDescriptionForPublish: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.overrideActiveKey && props.githubToken && props.jiraToken) {
            const activeKey = props.pullRequests.length > 0 ? COLLAPSE_KEY_PREVIEW : COLLAPSE_KEY_GITHUB_PULLS;
            if (props.pullRequests.length > 0) {
                return { ...state, activeKey, overrideActiveKey: true };
            }
            props.fetchGithubRepositories();
            return { ...state, activeKey: COLLAPSE_KEY_GITHUB_PULLS, overrideActiveKey: true };
        } else {
            return state;
        }
    }

    accordionOnSelect(activeKey) {
        this.setState((state) => ({ ...state, activeKey, overrideActiveKey: true }));
        if (activeKey === COLLAPSE_KEY_GITHUB_PULLS && this.props.githubToken) {
            this.props.fetchGithubRepositories();
        }
    }

    pullRequestsNeededButton() {
        return <Button
            variant="link"
            className="text-danger"
            onClick={() => { this.accordionOnSelect(COLLAPSE_KEY_GITHUB_PULLS); }}
        >
            Please select at least one pull request &nbsp; <i className="fas fa-arrow-right" />
        </Button>;
    }

    authNeededButton({ provider }) {
        return <Button
            variant="link"
            className="text-danger"
            onClick={() => { this.accordionOnSelect(COLLAPSE_KEY_AUTH); }}
        >
            Please sign into {provider} &nbsp; <i className="fas fa-arrow-right" />
        </Button>;
    }

    componentDidMount() {
        this.componentDidUpdate({});
    }

    componentDidUpdate({ pullRequests: prevPullRequests }) {
        const { pullRequests, githubToken, jiraToken } = this.props;
        const prevPullRequestIds = (prevPullRequests || []).map(({ id }) => id);
        const pullRequestIds = (pullRequests || []).map(({ id }) => id);
        const pullRequestsChanged = !((prevPullRequestIds.length === pullRequestIds.length) &&
            prevPullRequestIds.every((value, i) => value === pullRequestIds[i]));

        if (pullRequestsChanged && githubToken) {
            const issueDescription = new IssueDescription(githubToken, pullRequests, jiraToken);
            Promise.all([issueDescription.generate(true), issueDescription.generate(false)])
                .then(([issueDescriptionForPreview, issueDescriptionForPublish]) => {
                    this.setState((state => ({ ...state, issueDescriptionForPreview, issueDescriptionForPublish })));
                });
        }
    }

    // TODO error reporting in UI
    render() {
        const { githubToken, jiraToken, pullRequests } = this.props;
        return <div id="release-ticket">
            <h1 className="h3 mb-3">Create Release Ticket</h1>
            {/* TODO wizard instead of accordion: https://sharetown-inc.github.io/appstack/forms-wizard.html */}
            <Accordion activeKey={this.state.activeKey} onSelect={this.accordionOnSelect.bind(this)}>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey={COLLAPSE_KEY_AUTH}>
                        <Card.Title>
                            1. Auth
                            &nbsp;
                            {githubToken && jiraToken && <i className="fas fa-lg fa-check text-success" />}
                        </Card.Title>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={COLLAPSE_KEY_AUTH}>
                        <Card.Body style={{ paddingTop: 0 }}>
                            <div className="row justify-content-around">
                                <div className="col-sm-6 col-lg-4">
                                    <GithubButton />
                                </div>
                                <div className="col-sm-6 col-lg-4">
                                    <JiraButton />
                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey={COLLAPSE_KEY_GITHUB_PULLS}>
                        <Card.Title>
                            2. GitHub Pull Requests
                            &nbsp;
                            {pullRequests.length > 0 && <Badge pill variant="success">{pullRequests.length}</Badge>}
                        </Card.Title>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={COLLAPSE_KEY_GITHUB_PULLS}>
                        <Card.Body style={{ paddingTop: 0 }}>{(() => {
                            if (githubToken) {
                                return <PullRequestsSelector />;
                            } else {
                                return this.authNeededButton({ provider: 'GitHub' });
                            }
                        })()}</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey={COLLAPSE_KEY_PREVIEW}>
                        <Card.Title>
                            3. Preview
                        </Card.Title>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={COLLAPSE_KEY_PREVIEW}>
                        <Card.Body style={{ paddingTop: 0 }}>{(() => {
                            if (pullRequests.length > 0) {
                                return <IntlProvider locale="en">
                                    <ReactRenderer document={this.state.issueDescriptionForPreview} />
                                </IntlProvider>;
                            } else {
                                return this.pullRequestsNeededButton();
                            }
                        })()}</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey={COLLAPSE_KEY_PUBLISH}>
                        <Card.Title>
                            4. Publish
                        </Card.Title>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={COLLAPSE_KEY_PUBLISH}>
                        <Card.Body style={{ paddingTop: 0 }}>{(() => {
                            if (pullRequests.length === 0) {
                                return this.pullRequestsNeededButton();
                            } else if (!jiraToken) {
                                return this.authNeededButton({ provider: 'JIRA' });
                            } else {
                                return <Publish issueDescription={this.state.issueDescriptionForPublish} />;
                            }
                        })()}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>;
    }
}

ReleaseTicketComponent.propTypes = {
    jiraToken: PropTypes.shape({
        accessToken: PropTypes.string,
        refreshToken: PropTypes.string
    }),
    githubToken: PropTypes.shape({
        accessToken: PropTypes.string
    }),
    pullRequests: PropTypes.arrayOf(PropTypes.shape({
        html_url: PropTypes.string,
        title: PropTypes.string,
        base: PropTypes.object
    })),
    fetchGithubRepositories: PropTypes.func
};

export default connect(
    ({ auth, releaseTicket: { pullRequests } }) => ({ ...auth, pullRequests }),
    { fetchGithubRepositories }
)(ReleaseTicketComponent);
