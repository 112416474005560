import './App.css';
import ReleaseTickets from './releaseTickets/ReleaseTicket';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {setGithubToken, setJiraToken} from "./redux/actions";

const App = () => <Router>
    <main className="content">
        <div className="container">
            <Switch>
                <Route path="/release-ticket">
                    <ReleaseTickets />
                </Route>
                <Route path="/auth/github/callback">
                    <GithubCallback />
                </Route>
                <Route path="/auth/jira/callback">
                    <JiraCallback />
                </Route>
                <Route path="/">
                    <Redirect to="/release-ticket" />
                </Route>
            </Switch>
        </div>
    </main>
</Router>;

const GithubCallback = connect(null, { setGithubToken })((props) => {
    props.setGithubToken(window.location.href);
    // TODO dynamic redirect
    return <Redirect to="/release-ticket" />;
});

const JiraCallback = connect(null, { setJiraToken })((props) => {
    props.setJiraToken(window.location.href);
    return <Redirect to="/release-ticket" />;
});

export default App;
