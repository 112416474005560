import {
    APPEND_PULL_REQUEST,
    CLEAR_GITHUB_TOKEN,
    CLEAR_JIRA_TOKEN,
    FETCHING_GITHUB_CODE,
    FETCHING_GITHUB_REPOSITORIES,
    FETCHING_GITHUB_TOKEN,
    FETCHING_JIRA_CODE,
    FETCHING_JIRA_TOKEN, REMOVE_PULL_REQUEST,
    SET_GITHUB_REPOSITORIES,
    SET_GITHUB_TOKEN,
    SET_JIRA_TOKEN, SWAP_PULL_REQUESTS
} from "./actionTypes";
import {github, jira} from "../authClients";
import {Octokit} from "@octokit/core";
import jwtDecode from 'jwt-decode';

export const fetchGithubCode = () => ({ type: FETCHING_GITHUB_CODE });

export const fetchGithubToken = () => ({ type: FETCHING_GITHUB_TOKEN });

export const setGithubToken = (url) => async (dispatch, getState) => {
    if (!getState().auth.fetchingGithubToken) {
        dispatch(fetchGithubToken());
        try {
            const { accessToken } = await github.code.getToken(url);
            dispatch({ type: SET_GITHUB_TOKEN, payload: { githubToken: { accessToken } } });
        } catch (e) {
            console.error(e);
        }
    }
};

export const clearGithubToken = () => ({ type: CLEAR_GITHUB_TOKEN });

export const fetchJiraCode = () => ({ type: FETCHING_JIRA_CODE });

export const fetchJiraToken = () => ({ type: FETCHING_JIRA_TOKEN });

export const setJiraToken = (url) => async (dispatch, getState) => {
    if (!getState().auth.fetchingJiraToken) {
        dispatch(fetchJiraToken());
        try {
            const jiraTokenResponse = await jira.code.getToken(url);
            const decodedToken = jwtDecode(jiraTokenResponse.accessToken);
            const accessibleResourcesUrl = 'https://api.atlassian.com/oauth/token/accessible-resources';
            const cloudId = await fetch(accessibleResourcesUrl, jiraTokenResponse.sign({ headers: { 'Accept': 'application/json' } }))
                .then((response) => response.json())
                .then((json) => (json[0].id));
            dispatch({ type: SET_JIRA_TOKEN, payload: {
                jiraToken: {
                    accessToken : jiraTokenResponse.accessToken,
                    refreshToken: jiraTokenResponse.refreshToken,
                    payload: decodedToken,
                    cloudId
                }
            } });
        } catch (e) {
            console.error(e);
        }
    }
};

export const refreshJiraToken = () => async (dispatch, getState) => {
    const { auth: { fetchingJiraToken, jiraToken: { accessToken, refreshToken } } } = getState();
    if (!fetchingJiraToken) {
        const decodedToken = jwtDecode(accessToken);
        const expires_in = decodedToken.exp * 1000 - new Date();
        const token = jira.createToken(accessToken, refreshToken, 'bearer', { expires_in });
        if (token.expired()) {
            const freshToken = await token.refresh();
            const accessibleResourcesUrl = 'https://api.atlassian.com/oauth/token/accessible-resources';
            const cloudId = await fetch(accessibleResourcesUrl, freshToken.sign({ headers: { 'Accept': 'application/json' } }))
                .then((response) => response.json())
                .then((json) => (json[0].id));
            dispatch({ type: SET_JIRA_TOKEN, payload: {
                jiraToken: {
                    accessToken: freshToken.accessToken,
                    refreshToken: freshToken.refreshToken,
                    payload: decodedToken,
                    cloudId
                }
            } });
            return freshToken;
        } else {
            return token;
        }
    }
};

export const clearJiraToken = () => ({ type: CLEAR_JIRA_TOKEN });

export const fetchGithubRepositories = () => async (dispatch, getState) => {
    const { releaseTicket: { fetchingGithubRepositories }, auth: { githubToken } } = getState();
    if (!fetchingGithubRepositories) {
        dispatch({ type: FETCHING_GITHUB_REPOSITORIES });
        try {
            const octokit = new Octokit({ auth: githubToken.accessToken });
            const { data } = await octokit.request('GET /orgs/{org}/repos', { org: 'Sharetown-Inc' });
            dispatch({ type: SET_GITHUB_REPOSITORIES, payload: { githubRepositories: data } });
        } catch (e) {
            dispatch({ type: SET_GITHUB_REPOSITORIES, payload: { githubRepositories: [] } });
            console.error(e);
        }
    }
};

export const appendPullRequest = (pullRequest) => ({ type: APPEND_PULL_REQUEST, payload: { pullRequest } });

export const removePullRequest = (id) => ({ type: REMOVE_PULL_REQUEST, payload: { id } });

export const swapPullRequests = (indexA, indexB) => ({ type: SWAP_PULL_REQUESTS, payload: { indexA, indexB } });
