import ClientOAuth2 from "client-oauth2";

// TODO state parameter
export const github = new ClientOAuth2({
    clientId: `${process.env.REACT_APP_GITHUB_ID}`,
    clientSecret: 'TOP-SECRET',
    authorizationUri: 'https://github.com/login/oauth/authorize',
    accessTokenUri: `${process.env.REACT_APP_PROXY_HOST}/github/access_token`,
    redirectUri: `${window.location.origin}/auth/github/callback`,
    scopes: ['repo']
});

export const jira = new ClientOAuth2({
    clientId: `${process.env.REACT_APP_JIRA_CLIENT_ID}`,
    clientSecret: 'TOP-SECRET',
    authorizationUri: 'https://auth.atlassian.com/authorize',
    accessTokenUri: `${process.env.REACT_APP_PROXY_HOST}/jira/access_token`,
    redirectUri: `${window.location.origin}/auth/jira/callback`,
    scopes: ['read:jira-user', 'read:jira-work', 'write:jira-work', 'offline_access'],
    query: {
        audience: 'api.atlassian.com',
        prompt: 'consent'
    }
});
