export const FETCHING_GITHUB_CODE = 'FETCHING_GITHUB_CODE';
export const FETCHING_GITHUB_TOKEN = "FETCHING_GITHUB_TOKEN";
export const SET_GITHUB_TOKEN = "SET_GITHUB_TOKEN";
export const CLEAR_GITHUB_TOKEN = "CLEAR_GITHUB_TOKEN";
export const FETCHING_JIRA_CODE = 'FETCHING_JIRA_CODE';
export const FETCHING_JIRA_TOKEN = 'FETCHING_JIRA_TOKEN';
export const SET_JIRA_TOKEN = "SET_JIRA_TOKEN";
export const CLEAR_JIRA_TOKEN = "CLEAR_JIRA_TOKEN";

export const FETCHING_GITHUB_REPOSITORIES = 'FETCHING_GITHUB_REPOSITORIES';
export const SET_GITHUB_REPOSITORIES = 'SET_GITHUB_REPOSITORIES';

export const APPEND_PULL_REQUEST = 'APPEND_PULL_REQUEST';
export const REMOVE_PULL_REQUEST = 'REMOVE_PULL_REQUEST';
export const SWAP_PULL_REQUESTS = 'SWAP_PULL_REQUESTS';
