import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {refreshJiraToken} from "../redux/actions";
import {Button, Col, Form} from "react-bootstrap";
import moment from "moment";

const defaultJiraIssueSummary = () => {
    const deployDay = 3; // Wednesday
    const today = moment().weekday();
    const date = (today <= deployDay) ? moment().weekday(deployDay) : moment().weekday(7 + deployDay);
    return `release_${date.format('YYYYMMDD')}`;
};

class Publish extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingJiraProjects: false,
            jiraProjects: [],
            selectedJiraProjectId: '',
            jiraIssueSummary: defaultJiraIssueSummary(),
            publishing: false
        };
    }

    componentDidMount() {
        this.componentDidUpdate({});
    }

    componentDidUpdate({ jiraToken: prevJiraToken }) {
        if ((prevJiraToken || {}).refreshToken !== this.props.jiraToken.refreshToken) {
            this.loadProjects();
        }
    }

    async fetchCloudId(token) {
        const url = 'https://api.atlassian.com/oauth/token/accessible-resources';
        return fetch(url, token.sign({ headers: { 'Accept': 'application/json' } }))
            .then((response) => response.json())
            .then((json) => ({ cloudId: json[0].id, token: token }) );
    }

    loadProjects() {
        this.setState((state) => ({ ...state, loadingJiraProjects: true }));
        this.props.refreshJiraToken()
            .then((token) => this.fetchCloudId(token))
            .then(({ cloudId, token }) => fetch(`https://api.atlassian.com/ex/jira/${cloudId}/rest/api/3/issue/createmeta`, token.sign({ headers: { 'Accept': 'application/json' } })))
            .then((response) => response.json())
            .then(({ projects }) => {
                this.setState((state) => ({ ...state, jiraProjects: projects, loadingJiraProjects: false }));
            });
    }

    projectOnSelect({ target: { value } }) {
        this.setState((state) => ({ ...state, selectedJiraProjectId: value }));
    }

    jiraIssueSummaryOnChange({ target: { value } }) {
        this.setState((state) => ({ ...state, jiraIssueSummary: value }));
    }

    publish() {
        this.setState((state) => ({ ...state, publishing: true }));
        const { selectedJiraProjectId, jiraIssueSummary } = this.state;

        const {
            issueDescription,
            jiraToken: { payload: { sub } }
        } = this.props;
        const jiraAccountId = sub.replace('auth0|', '');
        const payload = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fields: {
                    project: { id: selectedJiraProjectId },
                    issuetype: { name: 'Task' },
                    summary: jiraIssueSummary,
                    description: issueDescription,
                    assignee: { id: jiraAccountId }
                }
            })
        };
        this.props.refreshJiraToken()
            .then((token) => this.fetchCloudId(token))
            .then(({cloudId, token}) => fetch(`https://api.atlassian.com/ex/jira/${cloudId}/rest/api/3/issue`, token.sign(payload)))
            .then((response) => response.json())
            .then(({ key }) => {
                window.open(`https://sharetown.atlassian.net/browse/${key}`, '_blank');
                setTimeout(() => {
                    this.setState((state) => ({ ...state, publishing: false, selectedJiraProjectId: '' }));
                }, 1000);
            });
    }

    render() {
        const { loadingJiraProjects, jiraProjects, selectedJiraProjectId, publishing, jiraIssueSummary } = this.state;
        return <div>
            <Form.Row>
                <Col sm={6} md={4}>
                    <Form.Control
                        as="select"
                        value={selectedJiraProjectId}
                        disabled={loadingJiraProjects || publishing}
                        onChange={this.projectOnSelect.bind(this)}
                    >
                        <option value="" disabled>Select a project...</option>
                        { jiraProjects.map(({ id, key, name }) =>
                            <option key={id} value={id}>{name} ({key})</option>
                        ) }
                    </Form.Control>
                </Col>
                <Col sm={4}>
                    <Form.Control
                        placeholder="Release title"
                        value={jiraIssueSummary}
                        disabled={publishing}
                        onChange={this.jiraIssueSummaryOnChange.bind(this)}
                    />
                </Col>
                <Col xs={2}>
                    <Button
                        variant="primary"
                        disabled={loadingJiraProjects || selectedJiraProjectId === '' || jiraIssueSummary === '' || publishing}
                        onClick={this.publish.bind(this)}
                    >
                        Publish
                    </Button>
                </Col>
            </Form.Row>
        </div>;
    }
}

Publish.propTypes = {
    issueDescription: PropTypes.object,
    jiraToken: PropTypes.shape({
        accessToken: PropTypes.string,
        refreshToken: PropTypes.string
    })
};

export default connect(
    ({ auth: { jiraToken } }) => ({ jiraToken }),
    { refreshJiraToken }
)(Publish);
