import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import {createTransform, persistReducer, persistStore} from "redux-persist";
import storage from 'redux-persist/lib/storage';

// TODO maybe instead of filtering keys, try setting these values one level deeper,
//      or in a generic object that gets wiped
const authTransform = createTransform(null, (state, key) => {
    switch (key) {
    case 'auth':
        return {
            ...state,
            fetchingGithubCode: false,
            fetchingGithubToken: false,
            fetchingJiraCode: false,
            fetchingJiraToken: false
        };
    case 'releaseTicket':
        return {
            ...state,
            fetchingGithubRepositories: false,
            githubRepositories: []
        };
    default:
        return state;
    }
});

const persistConfig = {
    key: 'root',
    storage,
    transforms: [authTransform]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk));

export const persistor = persistStore(store);
