import {connect} from "react-redux";
import {
    clearGithubToken,
    clearJiraToken,
    fetchGithubCode,
    fetchGithubRepositories,
    fetchJiraCode
} from "../redux/actions";
import {Button} from "react-bootstrap";
import {github, jira} from "../authClients";
import React from "react";

const GithubButtonComponent = (props) => {
    const { githubToken, fetchingGithubCode, fetchingGithubToken, fetchGithubCode, clearGithubToken } = props;
    if (githubToken) {
        return <div>
            GitHub token:
            <br />
            <input type="password" value={githubToken.accessToken} />
            <Button variant="link" onClick={clearGithubToken}>Clear</Button>
        </div>;
    } else {
        return <p>
            <Button
                href={github.code.getUri()}
                onClick={fetchGithubCode}
                variant="github"
                size="lg"
                block
                disabled={fetchingGithubCode || fetchingGithubToken}
            >
                <i className="fab fa-github" />
                &nbsp;
                { fetchingGithubCode || fetchingGithubToken ? 'Loading...' : 'Sign into GitHub' }
            </Button>
        </p>;
    }
};

const JiraButtonComponent = (props) => {
    const { jiraToken, fetchingJiraCode, fetchingJiraToken, fetchJiraCode, clearJiraToken } = props;
    if (jiraToken) {
        return <div>
            JIRA token:
            <br />
            <input type="password" value={jiraToken.accessToken} />
            <Button variant="link" onClick={clearJiraToken}>Clear</Button>
        </div>;
    } else {
        return <p>
            <Button
                href={jira.code.getUri()}
                onClick={fetchJiraCode}
                variant="bitbucket"
                size="lg"
                block
                disabled={fetchingJiraCode || fetchingJiraToken}
            >
                <i className="fab fa-jira" />
                &nbsp;
                { fetchingJiraCode || fetchingJiraToken ? 'Loading...' : 'Sign into JIRA' }
            </Button>
        </p>;
    }
};

export const GithubButton = connect(
    ({ auth }) => auth,
    { fetchGithubCode, clearGithubToken, fetchGithubRepositories }
)(GithubButtonComponent);

export const JiraButton = connect(
    ({ auth }) => auth,
    { fetchJiraCode, clearJiraToken }
)(JiraButtonComponent);
