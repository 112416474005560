import React from "react";
import {Button, Col, Form, Table} from "react-bootstrap";
import {Octokit} from "@octokit/core";
import {appendPullRequest, removePullRequest, swapPullRequests} from "../redux/actions";
import {connect} from "react-redux";

class PullRequestsSelectorComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRepository: '',
            selectedPullRequest: '',
            loadingPullRequests: false,
            availablePullRequests: []
        };
    }

    repositoryOnSelect({ target: { value } }) {
        if (!this.state.loadingPullRequests) {
            this.setState((state) => ({
                ...state,
                selectedRepository: value,
                availablePullRequests: [],
                loadingPullRequests: true,
                selectedPullRequest: ''
            }));
            const { githubToken, githubRepositories } = this.props;
            const octokit = new Octokit({ auth: githubToken.accessToken });
            const repo = githubRepositories.find(({ id }) => id.toString() === value);
            octokit.request(`GET ${repo.url}/pulls`)
                .then(({ data }) => ({ data: data.filter(({ base: { ref } }) => ref === 'main' || ref === 'master') }))
                .then(({ data }) => {
                    this.setState((state) => ({
                        ...state,
                        availablePullRequests: data,
                        loadingPullRequests: false,
                        selectedPullRequest: ''
                    }));
                }).catch((error) => {
                    console.error(error);
                });
        }
    }

    addPullRequest() {
        const { availablePullRequests, selectedPullRequest } = this.state;
        const pullRequest = availablePullRequests.find(({ id }) => id.toString() === selectedPullRequest);
        this.props.appendPullRequest(pullRequest);
        this.setState((state) => ({
            ...state,
            selectedPullRequest: '',
            selectedRepository: '',
            availablePullRequests: []
        }));
    }

    render() {
        const { pullRequests, fetchingGithubRepositories, githubRepositories } = this.props;
        const { selectedRepository, selectedPullRequest, loadingPullRequests, availablePullRequests } = this.state;
        return <div>
            <Table size="sm">
                <tbody>
                    { pullRequests.map((pullRequest, i) => <tr key={pullRequest.id}>
                        <td>{i + 1}.</td>
                        <td>{ pullRequest.base.repo.name }</td>
                        <td>{ pullRequest.title }</td>
                        <td>
                            <Button
                                variant="link"
                                size="sm"
                                className="text-danger"
                                onClick={() => this.props.removePullRequest(pullRequest.id)}
                            >
                                <i className="fas fa-times" />
                            </Button>
                            <Button
                                variant="link"
                                size="sm"
                                onClick={() => this.props.swapPullRequests(i, i - 1)}
                                disabled={i === 0}
                            >
                                <i className="fas fa-arrow-up" />
                            </Button>
                            <Button
                                variant="link"
                                size="sm"
                                onClick={() => this.props.swapPullRequests(i, i + 1)}
                                disabled={i + 1 === pullRequests.length}
                            >
                                <i className="fas fa-arrow-down" />
                            </Button>
                        </td>
                    </tr>) }
                    <tr><td colSpan={4} /></tr>
                </tbody>
            </Table>
            <Form.Row>
                <Col sm>
                    <Form.Control
                        as="select"
                        value={selectedRepository}
                        disabled={fetchingGithubRepositories}
                        onChange={this.repositoryOnSelect.bind(this)}
                    >
                        <option value="" disabled>Select a repo...</option>
                        { githubRepositories.map(({ id, name }) =>
                            <option key={id} value={id}>{ name }</option>
                        ) }
                    </Form.Control>
                </Col>
                <Col sm>
                    <Form.Control
                        as="select"
                        value={selectedPullRequest}
                        disabled={selectedRepository === '' || loadingPullRequests}
                        onChange={({ target: { value } }) => {
                            this.setState((state) => ({ ...state, selectedPullRequest: value }));
                        }}
                    >
                        <option value="" disabled>Select a pull request...</option>
                        { availablePullRequests.map(({ id, title }) =>
                            <option key={id} value={id}>{ title }</option>
                        ) }
                    </Form.Control>
                </Col>
                <Col sm={2}>
                    <Button
                        variant="primary"
                        disabled={selectedPullRequest === ''}
                        onClick={this.addPullRequest.bind(this)}
                    >
                        Add
                    </Button>
                </Col>
            </Form.Row>
        </div>;
    }
}

export default connect(
    ({ auth: { githubToken }, releaseTicket }) => ({ ...releaseTicket, githubToken }),
    { appendPullRequest, removePullRequest, swapPullRequests }
)(PullRequestsSelectorComponent);
