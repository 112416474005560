import {
    APPEND_PULL_REQUEST,
    FETCHING_GITHUB_REPOSITORIES,
    REMOVE_PULL_REQUEST,
    SET_GITHUB_REPOSITORIES, SWAP_PULL_REQUESTS
} from "../actionTypes";

const initialState = {
    fetchingGithubRepositories: false,
    githubRepositories: [],
    pullRequests: []
};

const releaseTicket = (state = initialState, action) => {
    switch (action.type) {
    case FETCHING_GITHUB_REPOSITORIES:
        return { ...state, fetchingGithubRepositories: true, githubRepositories: [] };
    case SET_GITHUB_REPOSITORIES:{
        const { githubRepositories } = action.payload;
        return { ...state, fetchingGithubRepositories: false, githubRepositories };
    }
    case APPEND_PULL_REQUEST:{
        const { pullRequest } = action.payload;
        return { ...state, pullRequests: [...state.pullRequests, pullRequest] };
    }
    case REMOVE_PULL_REQUEST:{
        const { id } = action.payload;
        return { ...state, pullRequests: state.pullRequests.filter((pullRequest) => pullRequest.id !== id) };
    }
    case SWAP_PULL_REQUESTS:{
        const { indexA, indexB } = action.payload;
        const swappedPullRequests = state.pullRequests.slice();
        const pullRequestA = swappedPullRequests[indexA];
        swappedPullRequests[indexA] = swappedPullRequests[indexB];
        swappedPullRequests[indexB] = pullRequestA;
        return { ...state, pullRequests: swappedPullRequests };
    }

    default:
        return state;
    }
};

export default releaseTicket;
